.HeroHeaderAndImage {
  display: grid;
  align-items: center;
  gap: 48px clamp(48px, calc(48vw / 7.68), 64px);
  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    .img {
      order: -1;
    }
  }
  @media (max-width: 999px) {
    header {
      max-width: calc(450rem / 16);
    }
    .img {
      margin: 0 auto;
    }
  }
  .paragraph {
    margin: clamp(12px, calc(16vw / 7.68), 20px) 0 clamp(24px, calc(28vw / 7.68), 36px);
  }
  .img {
    max-width: fit-content;
    position: relative;
    img {
      max-width: clamp(286px, calc(358vw / 7.68), 456px);
      margin: auto;
      display: block;
    }
    .DecorationIcon {
      width: clamp(469px, calc(655vw / 7.68), 742px);
      z-index: -1;
      position: absolute;
      right: -15%;
      bottom: 0;
      .line {
        animation: line 2.3s var(--easing) both;
        @keyframes line {
          0% {
            stroke-dasharray: 0 1536;
          }
          100% {
            stroke-dasharray: 1536 1536;
          }
        }
      }
      .stars {
        path {
          animation: stars 0.5s var(--easing) both;
          transform-origin: center;
          transform-box: fill-box;
          &:nth-child(1) {
            animation-delay: 0.6s;
          }
          &:nth-child(2) {
            animation-delay: 0.8s;
          }
        }
        @keyframes stars {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
      }
      .brush {
        path {
          animation: brush 0.5s var(--easing) both;
          transform-origin: center;
          transform-box: fill-box;
          &:nth-child(1) {
            animation-delay: 1.3s;
          }
          &:nth-child(2) {
            animation-delay: 1.4s;
          }
          &:nth-child(3) {
            animation-delay: 1.5s;
          }
        }
        @keyframes brush {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
  }
}
