.Button {
  border-radius: 48px;
  border: 1px solid var(--primary-600, #f489a9);
  background-color: var(--primary-500, #ffb8ce);
  font-size: calc(14rem / 16);
  color: var(--secondary-600, #504752);
  padding: 13px 36px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-start;
  text-align: center;
  @media (max-width: 499px) {
    width: 100%;
  }
  position: relative;
  .Star1Icon,
  .Star2Icon,
  .Star3Icon {
    position: absolute;
    transition: transform 0.3s var(--easing), opacity 0.3s;
    opacity: 0;
    transform: scale(0) translateY(3px);
  }
  .Star1Icon {
    right: -9px;
    bottom: 6px;
    transition-delay: 0.2s;
  }
  .Star2Icon {
    right: 7px;
    bottom: 17px;
    transition-delay: 0.1s;
  }
  .Star3Icon {
    right: -18px;
    top: -5px;
    transition-delay: 0s;
  }
  transition: background-color 0.5s;
  &:hover,
  &:focus-visible {
    background-color: var(--primary-400, #ffd7e4);
    .Star1Icon {
      transition-delay: 0s;
    }
    .Star2Icon {
      transition-delay: 0.1s;
    }
    .Star3Icon {
      transition-delay: 0.2s;
    }
    .Star1Icon,
    .Star2Icon,
    .Star3Icon {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
  &:active {
    .Star1Icon,
    .Star2Icon,
    .Star3Icon {
      transform: scale(1.5) translateY(0);
    }
  }
  &:disabled {
    opacity: 0.62;
    pointer-events: none;
  }
}
